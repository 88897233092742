import React from 'react';
import * as Styled from './locationMarkerStyle';
import { useTranslation } from 'react-i18next';
import { addTrailingSlash } from '../../utils';

const LocationMarker = (props) => {
  const [translation] = useTranslation();
  let phones;
  const offerings = [
    {
      boolean: props?.node?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: props?.node?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: props?.node?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: props?.node?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: props?.node?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: props?.node?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: props?.node?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
    {
      boolean: props?.node?.hasDef,
      name: translation('Def'),
    },
    {
      boolean: props?.node?.hasShowers,
      name: translation('Showers'),
    },
    {
      boolean: props?.node?.hasRestrooms,
      name: translation('Restroom'),
    },
    {
      boolean: props?.node?.hasWifi,
      name: translation('Wi-Fi'),
    },
    {
      boolean: props?.node?.hasParking,
      name: translation('Parking'),
    },
    {
      boolean: props?.node?.hasRestaurant,
      name: translation('Restaurant'),
    },
    {
      boolean: props?.node?.hasConvenienceStore,
      name: translation('ConvenienceStore'),
    },
    {
      boolean: props?.node?.hasLunchCounter,
      name: translation('LunchCounter'),
    },
    {
      boolean: props?.node?.hasDriversLounge,
      name: translation('DriversLounge'),
    },
    {
      boolean: props?.node?.hasMultiServices,
      name: translation('MultiServices'),
    },
  ];

  const StringGenerator = (object) => {
    let header = '';
    let length = 0;
    object.map((item) => {
      if (item.boolean) {
        if (length !== 0) {
          header += ', ' + item.name;
          length++;
        } else {
          header += item.name;
          length++;
        }
      }
    });
    return header;
  };
  const [showPopup, setShowPopup] = React.useState(false);
  const handleClick = () => {
    if (props.allowPopup) {
      setShowPopup(true);
      props.onMarkerClick(props);
    }
  };

  const markerStyle = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    height: '50px',
    transform: 'translate(-50%, -100%)',
  };

  return (
    <div>
      {showPopup && props.id === props.currentMarker && (
        <Styled.MainDiv>
          <Styled.InnerDiv>
            <Styled.PopupCloseStyle
              onClick={() => {
                setShowPopup(false);
              }}
            >
              x
            </Styled.PopupCloseStyle>
            <Styled.InfoWindowStyle>
              <Styled.InfoWindowLeft>
                <Styled.InfoWindowH2>
                  {props?.node?.location}
                </Styled.InfoWindowH2>
                <Styled.ParaText></Styled.ParaText>
                <Styled.ParaText>
                  {props?.node?.address1}
                  <br />
                  {props?.node?.city + ', ' + props?.node?.province}

                  {props?.node?.postalCode}
                </Styled.ParaText>
                <Styled.AnchorStyle
                  href={`mailto:${props.node.email}`}
                  tabindex="0"
                >
                  {props.node.email}
                </Styled.AnchorStyle>
                {
                  ((phones = props?.node?.phone.split(' ')),
                  phones.map((phone, index) => (
                    <Styled.AnchorStyle
                      key={index}
                      href={`tel:${phone}`}
                      tabindex="0"
                    >
                      {phone}
                    </Styled.AnchorStyle>
                  )))
                }
              </Styled.InfoWindowLeft>
              <Styled.InfoWindowRight>
                <Styled.InfoWindowH3>Branch Manager</Styled.InfoWindowH3>
                <Styled.ParaText>{props?.node?.branchManager}</Styled.ParaText>
                <Styled.InfoWindowH3>Branch Hours</Styled.InfoWindowH3>
                <Styled.ParaText>{props?.node?.branchHours}</Styled.ParaText>
                {props.showServices && (
                  <Styled.LinkStyle
                    to={addTrailingSlash(
                      '/locations/details/' + props.node.urlSlug
                    )}
                  >
                    View Service Areas
                  </Styled.LinkStyle>
                )}
              </Styled.InfoWindowRight>
            </Styled.InfoWindowStyle>
          </Styled.InnerDiv>
        </Styled.MainDiv>
      )}
      <img style={markerStyle} src={props.src} onClick={handleClick} />
    </div>
  );
};

export default LocationMarker;
